import React, { useState, useRef, useImperativeHandle, forwardRef } from "react";
import { useSelector } from "react-redux";
import { Divider, Modal } from "antd";
import { Form, Formik } from "formik";
import scheme from "./Validations";
import { CButton, CField } from "../../uiComponents";
import Dragger from "antd/es/upload/Dragger";
import { IMAGE_FILETYPE } from "../../utils/constants";
import { getBase64, handleError, isImage, openFileInBrowser } from "../../utils/methods";
import { AiOutlineDelete } from "react-icons/ai";
import { InboxOutlined } from "@ant-design/icons";
import { FileIcon } from "../../assets/svg";

const CReason = forwardRef(({ onSubmit = () => { }, title = '' }, ref) => {

    const form = useRef(null);

    const reduxState = useSelector(({ employees }) => {
        return {
            loading: employees?.cardActivateOrBlockLoading,
        };
    });

    const { loading } = reduxState;

    const [isOpen, setIsOpen] = useState(false);
    const [payload, setPayload] = useState({});

    useImperativeHandle(ref, () => ({
        setIsOpen,
        setPayload
    }));

    const onCancel = () => {
        if (!loading) {
            setIsOpen(false);
        }
    };

    const onSubmitHandler = (values) => {
        if (Object.keys(payload)?.length) {
            onSubmit({ ...payload, reason: values?.reason, ...(values?.attachmentFile && { attachmentFile: values?.attachmentFile }) })
        } else {
            onSubmit({ reason: values?.reason, ...(values?.attachmentFile && { attachmentFile: values?.attachmentFile }) })
        }
    }

    const renderImage = (file) => {
        let setFieldValue = form.current?.setFieldValue;
        try {
            if (file && Object.keys(file)?.length) {
                return (
                    <div className="c-preview-list">
                        <div className="preview margin-right_zero">
                            <div className="left" onClick={() => openFileInBrowser(file, false)}>
                                <div className="thumbnail">
                                    {isImage(file) ? <img src={file?.thumbUrl} /> : <FileIcon />}
                                </div>
                                <span>
                                    <div>{file?.name}</div>
                                </span>
                            </div>
                            {!loading ? <AiOutlineDelete className="c-icon" onClick={() => setFieldValue(`attachmentFile`, {})} /> : null}
                        </div>
                    </div>
                );
            }
        } catch (e) {
            console.log(e);
        }
    };

    const handleFileChange = async (info, setFieldValue) => {
        const { status, originFileObj = {} } = info?.file;
        if (status !== 'uploading') {
            if (originFileObj?.type.includes("jpeg") || originFileObj?.type.includes("jpg") || originFileObj?.type.includes("png") || originFileObj?.type.includes("webp")) {
                await getBase64(originFileObj).then((uri) => {
                    originFileObj.thumbUrl = uri;
                    setFieldValue('attachmentFile', originFileObj);
                });
            } else {
                handleError("Only Image files are allowed!");
            }
        }
    }

    return <Modal
        title={title || 'Enter Reason'}
        width={450}
        centered
        open={isOpen}
        maskClosable={false}
        onCancel={onCancel}
        cancelButtonProps={{ hidden: true }}
        okButtonProps={{ hidden: true }}
        destroyOnClose
    >
        <Formik
            innerRef={form}
            validationSchema={scheme}
            validateOnChange={true}
            validateOnBlur={true}
            initialValues={{ reason: '' }}
            onSubmit={onSubmitHandler}
        >
            {({ errors, touched, handleSubmit, values, setFieldTouched, submitCount, handleChange, setFieldValue }) => {
                let fileError = submitCount ? errors?.attachmentFile : touched?.attachmentFile && errors?.attachmentFile;
                return <Form>
                    <CField
                        name="reason"
                        placeholder="Reason"
                        fieldType="textArea"
                        containerClass={"flex0 margin-right_zero"}
                        error={submitCount ? errors?.reason : touched?.reason && errors.reason}
                        onBlur={() => setFieldTouched("reason", true, true)}
                        disabled={loading}
                        value={values?.reason}
                        onChange={(e) => handleChange('reason')(e.target.value)}
                    />
                    <Divider />
                    <Dragger
                        onChange={(info) => handleFileChange(info, setFieldValue)}
                        accept={`${IMAGE_FILETYPE}`}
                        showUploadList={false}
                        height={150}
                        disabled={loading}
                        className={`w-100 margin-top_15 margin-bottom_15 ${fileError ? "error" : ""}`}
                    >
                        <p className="ant-upload-drag-icon"><InboxOutlined /></p>
                        <p className="ant-upload-text">Click or drag file here to upload</p>
                        <p className="ant-upload-hint">Only images are allowed</p>
                    </Dragger>

                    {fileError ? <p className="small-red-text" >{errors?.attachmentFile}</p> : null}

                    {renderImage(values?.attachmentFile)}

                    <CButton
                        containerClass="flex0 margin-right_zero"
                        onClick={handleSubmit}
                        type="submit"
                        htmlType="submit"
                        title={'Submit'}
                        loading={loading}
                        disabled={loading}
                    />
                </Form>
            }}
        </Formik>

    </Modal>
});

export default CReason;